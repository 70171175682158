
import React from "react";
import { Link } from "react-router-dom";

function Links(params) {
    let newtab = "";
    if (params.target === 'true' && params.target) {
        newtab = { 'target': '_blank' }
    }
    let title = "";
    if (params.title !== '' && params.title) {
        title = { 'title': params.title }
    }
    let url = "";
    let hrefOrLink = "";
    if (params.url !== "") {
        if (params.url.hasOwnProperty('link')) {
            url = params.url.link;
        } else if (params.url.hasOwnProperty('path')) {
            url = params.url.path;
        } else {
            url = params.url;
        }
    } else {
        url = "";
    }
    if (url.includes("/sites/widzew") && url !== "") {
        url = url.split('/sites/widzew');
        url = url[1];
        hrefOrLink = "isLink";
    } else if ((url.includes("http://") || url.includes("https://")) && url !== "") {
        if ((url.includes("offline-widzew") && params.line === 'online') || url.includes("/widzew.com/")) {//for details broken link with no export/sites
            let uri = new URL(url);
            url = uri.pathname;
            hrefOrLink = "isLink";
        }
        else {
            hrefOrLink = "isHref";
        }
    } else {
        if (url.endsWith('.pdf') || url.endsWith('.jpg')) {
            hrefOrLink = "isHref";
        } else {
            hrefOrLink = "isLink";
        }
    }

    return (
        (params.line === 'offline' || hrefOrLink === "isHref")
            ?
            <a href={url} className={params.class} {...title} {...newtab}>{params.name}</a> 
            : (hrefOrLink === "isHref"
                ?
                <Link to={url} className={params.class} {...newtab}>{params.name}</Link>
                :
                <Link to={url} className={params.class}>{params.name}</Link>
                )
    )
}

export default Links;